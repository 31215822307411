import moment from 'moment'

export const fillTemplate = function(templateString, templateVars){
    return templateString.replace(/\${(.*?)}/g, (x,g)=> templateVars[g]);
} 

export const numRoundoff = function (num){
    let num_roundoff = "0"

    if (typeof num == 'undefined')
        return num_roundoff
    else if (num > 9){
        num_roundoff = Math.round((num-5)/10)*10
        num_roundoff += '+'
    }
    else 
        num_roundoff = num.toString() 

    return num_roundoff

}

export const hyphenatedName = function (name) {
    let str = ''
    if(name)
        str = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    str = str.replace('&', 'and').replace(/[^a-zA-Z0-9 ]/g, "")
    return str.split(' ').join('-').toLowerCase()
}

export const formatName = function(name) {
    let fullName = name

    if(name !== null && name !== undefined){
        const formattedName = name.split(',')

        if(formattedName.length > 1){
            const lastName = formattedName[0].replace(/^\s+|\s+$/g, '')
            const firstName = formattedName[1].replace(/^\s+|\s+$/g, '')
            fullName = firstName + ' ' + lastName
        }
    }

    return fullName
}
 
export const formatDate = function(iso_date) {
    try{
        return moment(iso_date).format('h:mm A . D MMM YYYY')
    }
    catch(e){
        return iso_date
    }
}